
const TokenView = () => {
    const token = localStorage.getItem('LoginStatus');
     localStorage.clear();
    return (
        <>
            <div style={{"word-wrap": "break-word"}}>{token}</div>
        </>
    );
};

export default TokenView;
import React from "react";

const Scanner = ({handleChange,data,handleKeyPressLogin}) =>{
 
    return(
        <>
            <div className="form-group">
                <label htmlFor="scanner">Scan ID Card</label>
                <input type="password" className="form-control" autoFocus autocomplete="nope" value={data} name="scanner" onKeyDown={handleKeyPressLogin} onChange={(e)=>{handleChange(e.target.value)}} />
            </div>            
        </>
    )
}
export default Scanner;

import './App.css';
import Login from "./Components/Login/Login.jsx";
import Warning from './Components/WarningMessage/Warning.jsx';
import TokenView from './Components/TokenView/TokenView.jsx';
import {Routes,Route} from "react-router-dom";

function App() {
    return ( 
        <Routes>
            <Route path="*" element={<Login/>}/>
            <Route path="/login" name="login" element={<Login/>}/>
            <Route path="/warning" name="warning" element={<Warning/>}/>
            <Route path="/token" name="token" element={<TokenView/>}/>
         </Routes>
    );
}

export default App;
import React,{ useEffect,useState } from 'react';
import Logo from '../../assets/logo.png' ;
import '../WarningMessage/warning.css';


const Warning = () =>{

    const[time, setTime] = useState(5);

   useEffect(() =>{
    time > 0 && setTimeout(() => {
       setTime(time-1);
    }, 1000);
    if(time === 0){
        window.location.href ='https://www.aims360.com/';
    }
   },[time])

    return(
        <section style={{backgroundColor:'#24252f', height:'100vh'}}>
       <div className="d-flex justify-content-center  align-items-center container warn-sd834-height">
           <div className="px-2">
           <div className="text-center">
                <img src={Logo}  className="warn-sbd83-logo" alt="LOGO" />
            </div>
               <p className="text-center text-white warn-ba39-text mt-4" >The Page you have requested cannot be authorized, Maybe your client account was not registred with Runway yet.</p>
                <p className="text-center text-white warn-3b7d-mailtext" >Please contact <a href="mailto:support@aims360.com">support@aims360.com</a></p>
                <p className="text-center text-white warn-dstw98-timetext">The page will be automatically redirected to <a href="https://www.aims360.com/">aims360</a> website with in {time} secs</p>
           </div>
       </div>
        </section>
    )
}

export default Warning;